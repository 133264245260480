import Landing from "organisms/landing";
import { getCoinsServerSideProps } from "backend/coins";
import { makePageRateLimit } from "@backend/rateLimit";
import timeBomb from "@backend/timeBomb";
import getPage from "@helpers/getPage";

const Today = (props) => {
  return <Landing {...props} type="today" />;
};

const pageRateLimit = makePageRateLimit({
  points: 5,
  duration: 2,
  blockDuration: 10,
});

const mainPageRateLimit = makePageRateLimit({
  points: 6,
  duration: 2,
  blockDuration: 4,
});

export async function getServerSideProps(context) {
  const { query, ...rest } = context;

  const page = getPage(query.page);
  const rateResponse =
    page > 1 && (await pageRateLimit(context, "recentVotes"));

  if (rateResponse) {
    console.log("block", "recentVotes", page);
    return rateResponse;
  }

  const mainRateResponse =
    page === 1 && (await mainPageRateLimit(context, "recentVotes"));

  if (mainRateResponse) {
    console.log("block main", "recentVotes", page);
    return mainRateResponse;
  }

  return timeBomb(context, () =>
    getCoinsServerSideProps({
      ...rest,
      query: { ...query, sort: "recentVotes", news: true },
    })
  );
}

export default Today;
